.landingContainer {
  padding: 0;
  margin: 0;
  width: 100vw;
  height: calc(100vh - 100px);
}

.spacer {
  height: 12vh;
}

.landingContent {
  width: 100vw;
  margin: auto;
}

.title {
  font-size: 70px;
  font-weight: bold;
  color: white;
}

.underlineContainer {
  display:flex;
  flex-direction: row;
  justify-content: center;
}

.underline {
  width: 500px;
  height: 2px;
  background-color: none;
}

.information {
  margin-top: 20px;
  font-size: 24px;
  font-weight: 400;
  color: white;
  line-height: 40px;
}

.buttonContainer {
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 500px;
}

.landingButton {
  height: 50px;
  width: 200px;
  border-radius: 10px;
  background-color: #007f5f;
  color: white;
  font-size: 15px;
  text-decoration: none;
  font-weight: 600;
}

.landingButton:hover {
  color: rgb(199, 199, 199);
  transition: 0.5s;
}

.landingButtonText {
  margin-top: 14px;
  color: white;
}

.nftreeStats {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 120px;
  width: 100%;
  background-color: none;
  text-align: center;
  column-count: 3;
}

.statCol {
  font-size: 24px;
  font-weight: 400;
  color: #fff;
  line-height: 50px;
  text-align: center;
}

.statValue {
  font-size: 40px;
}

.attribution {
  position: absolute;
  bottom: 0pt;
  right: 0pt;
  font-size: 12px;
  text-decoration: none !important;
  color: #16242D !important;
}

@media only screen and (max-width: 1300px) {
  .spacer {
    height: 15vh;
  }

  .title {
    font-size: 50px;
  }

  .information {
    font-size: 20px;
    line-height: 30px;
  }

  .nftreeStats {
    height: 100px;
    position: fixed;
    left: 0;
    width: 100%;
    background-color: none;
    text-align: center;
    column-count: 3;
  }
  
  .statCol {
    font-size: 20px;
    font-weight: 400;
    color: #fff;
    line-height: 50px;
    text-align: center;
  }
  
  .statValue {
    font-size: 22px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 770px) {
  .title {
    font-size: 36px;
  }

  /*
  .information {
    margin: auto;
    width: 90%;
    font-size: 18px;
    line-height: 30px;
    padding-top: 20px;
  }*/

  .buttonContainer {
    width: 300px;
  }

  .landingButton {
    width: 120px;
    height: 34px;
    font-size: 12px;
  }

  .landingButtonText {
    margin-top: 8px;
  }

  .nftreeStats {
    height: 66px;
  }

  .statCol {
    font-size: 16px;
    line-height: 30px;
  }

  .statValue {
    font-size: 16px;
  }

}

@media only screen and (max-width: 650px) {
  .information {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }
}

@media only screen and (max-width: 460px) {
  .statCol {
    font-size: 14px;
    line-height: 20px;
  }

  .statValue {
    font-size: 16px;
  }

  .nftreeStats {
    height: 60px;
  }

  .title {
    font-size: 32px;
  }

  .information {
    font-size: 14px !important;
  }

  /*
  .buttonContainer {
    width: 260px;
  }

  .landingButton {
    width: 100px;
    height: 28px;
    font-size: 10px;
  }

  .landingButtonText {
    margin-top: 6px;
  }*/
}

@media only screen and (max-width: 370px) {
  .landingTitle {
    font-size: 24px;
    margin-left: 6px;
  }
  .statCol {
    font-size: 12px;
    line-height: 12px;
  }

  .statValue {
    font-size: 14px;
  }

  .nftreeStats {
    height: 42px;
  }

  .attribution {
    display: none;
  }
}

@media only screen and (max-width: 640px) and (max-height: 420px) {
  .nftreeStats {
    display: none !important;
  }
}

@media only screen and (max-width: 640px) and (max-height: 440px) {
  .nftreeStats {
    display: none !important;
  }
}

@media only screen and (max-width: 520px) and (max-height: 320px) {
  .title {
    font-size: 20px !important;
  }

  .information {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 320px) {
  .title {
    font-size: 32px;
  }

  .information {
    font-size: 14px;
  }

  .buttonContainer {
    width: 260px;
  }

  .landingButton {
    width: 100px;
    height: 28px;
    font-size: 10px;
  }

  .landingButtonText {
    margin-top: 6px;
  }

  .attribution {
    display: none;
  }

  .statCol {
    font-size: 10px;
    line-height: 12px;
  }

  .statValue {
    font-size: 12px;
  }

  .nftreeStats {
    height: 42px;
  }
}

@media only screen and (max-width: 320px) {
  .nftreeStats {
    display: none;
  }
}

@media only screen and (max-height: 750px) {
  .spacer {
    height: 12vh;
  }
}

@media only screen and (max-height: 700px) and (min-width: 770px) {
  .spacer {
    height: 6vh;
  }

  .title {
    font-size: 46px;
  }

  .information {
    font-size: 24px;
    line-height: 42px;
  }

  .landingButton {
    width: 180px;
    height: 46px;
    font-size: 16px;
  }

  .landingButtonText {
    margin-top: 10px;
  }

  .statCol {
    font-size: 22px;
    line-height: 30px;
  }

  .statValue {
    font-size: 24px;
  }

  .nftreeStats {
    height: 66px;
  }
  
}

@media only screen and (max-height: 530px) {
  .title {
    font-size: 26px;
  }

  .information {
    font-size: 16px;
    line-height: 32px;
    margin-top: 0;
  }

  .buttonContainer {
    margin-top: 0;
  }

  .landingButton {
    width: 120px;
    height: 34px;
    font-size: 12px;
  }

  .landingButtonText {
    margin-top: 8px;
  }
}

@media only screen and (max-height: 480px) and (max-width: 310px) {
  .nftreeStats {
    display: none !important; 
  }
}

@media only screen and (max-height: 450px) and (max-width: 580px) {
  .title {
    font-size: 24px;
  }

  /*
  .information {
    font-size: 16px;
    line-height: 24px;
    padding-top: 10px;
  }*/

  .landingButton {
    width: 120px;
    height: 32px;
    font-size: 10px;
  }

  .landingButtonText {
    margin-top: 8px;
  }

  .attribution {
    display: none;
  }
}

@media only screen and (max-height: 400px) {
  .nftreeStats {
    height: 60px;
  }

  .statCol {
    font-size: 14px;
    line-height: 24px;
  }

  .statValue {
    font-size: 16px;
  }
}

@media only screen and (max-height: 380px) {
  .nftreeStats {
    display: none !important;
  }
}
