* {
  box-sizing: border-box;
}

.App {
  height: auto;
  width: 100%;
  text-align: center;
  overflow: hidden;
  font-family: 'Montserrat', sans-serif;
  overflow-y: auto;
}

.landingBackground {
  background-image: url('./assets/test.jpg');
  background-position: center;
  background-size: cover;
}

.loading {
  font-size: 100px;
  color: #fff;
}
