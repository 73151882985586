.navContainer {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  height: 100px;
}

.logo {
  text-decoration: none;
  height: 50px;
  padding-left: 50px;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
}

.landingTitle {
  font-size: 32px;
  font-weight: bold;
  color: #16242d;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
}

.split {
  width: 3px;
}

.launchButton {
  display: flex; 
  justify-content: center;
  margin-top: 32px;
  margin-right: 50px;
  height: 44px;
  width: 170px;
  border-radius: 10px;
  background-color: #52b788;
  color: rgb(138, 133, 133);
  font-size: 18px;
  text-decoration: none;
  font-weight: 600;
}

.launchButton:hover {
  color: rgb(199, 199, 199);
  transition: 0.5s;
}

.launchButtonText {
  margin: auto;
  color: #fff;
}

.textLink {
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  color: #16242d;
  margin-top: 40px;
  margin-right: 60px;
  padding: 0;
}

.nav-link {
  color: black !important;
  padding:0 !important;
}

.nav-link::after {
  display: none !important;
}

.dropdown:hover>.dropdown-menu {
  display: block;
}

.dropdown-menu {
  min-width: 50px !important;
  border-radius: 10px !important;
  background-color: #719bb3bd !important;
}

.dropdown-item {
  font-weight: 600 !important;
  color: #fff !important;
}

.dropdown-item:hover {
  background-color: transparent !important;
}

.space {
  margin:auto;
}

.navbarRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}


.icon:hover {
  fill:rgb(199, 199, 199);
  transition: .5s;
}

@media only screen and (max-width: 900px) {
  .logo {
    text-decoration: none;
    height: 50px;
    padding-left: 30px;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
  }

  .textLink {
    display: none;
  }

  .launchButton {
    display: none;
  }


}